import React, { useState, useEffect } from 'react';
import { motion } from "framer-motion";

const Resultados = () => {

  const [ resultados, setResultados ] = useState([]);

  function traerImagenTorneo(club){
    const objClub = {
      url: require("../img/img_logo_swimmingT.png"),
      colorTitulo: 'rgb(0,0,0)',
      colorFecha: 'rgb(0,0,0)'
    }
    switch (club) {
      case 'piranitas':
        objClub.url = require("../img/img-fondo-piranitas.webp")
        objClub.colorTitulo = 'linear-gradient(225deg, #CBC2AC, #2E3939)'
        objClub.colorFecha = '#2E3939'
        break;
      case 'polares':
        objClub.url = require("../img/img-fondo-polares.webp")
        objClub.colorTitulo = 'linear-gradient(225deg, #9CBDC3, #0F5D7D)'
        objClub.colorFecha = '#0F5D7D'
        break;
      case 'orcas-valle': 
        objClub.url = require("../img/img-fondo-orcasvalle.webp")
        objClub.colorTitulo = 'linear-gradient(225deg, #4991BF, #183D58)'
        objClub.colorFecha = '#183D58'
        break;
      case 'linavalle': 
        objClub.url = require("../img/img-fondo-linavalle.webp")
        objClub.colorTitulo = 'linear-gradient(225deg, #D0161D, #050D12)'
        objClub.colorFecha = '#050D12'
        break;
      case 'karina-vivas': 
        objClub.url = require("../img/img-fondo-karinavivas.webp")
        objClub.colorTitulo = 'linear-gradient(225deg, #7FADC0, #1A5881)'
        objClub.colorFecha = '#1A5881'
        break;
      case 'fundacion-arbe': 
        objClub.url = require("../img/img-fondo-arbe.webp")
        objClub.colorTitulo = 'linear-gradient(225deg, #C3D6EA, #093A8F)'
        objClub.colorFecha = '#093A8F'
        break;
      case 'fecna': 
        objClub.url = require("../img/img-fondo-fecna.webp")
        break;
      case 'atlas-narino': 
        objClub.url = require("../img/img-fondo-atlas.webp")
        objClub.colorTitulo = 'linear-gradient(225deg, #749DB6, #070F1D)'
        objClub.colorFecha = '#070F1D'
        break;
      case 'leones-acuaticos': 
        objClub.url = require("../img/img-fondo-leonesacuaticos.webp")
        objClub.colorTitulo = 'linear-gradient(225deg, #CBC9C8, #616F7A)'
        objClub.colorFecha = '#616F7A'
        break;
      case 'tiburones-blancos': 
        objClub.url = require("../img/img-fondo-tiburonesblancos.webp")
        objClub.colorTitulo = 'linear-gradient(225deg, #589BA5, #1F3A4B)'
        objClub.colorFecha = '#1F3A4B'
        break;
      case 'kairos': 
        objClub.url = require("../img/img-fondo-kairos.webp")
        objClub.colorTitulo = 'linear-gradient(225deg, #379CB0, #045478)'
        objClub.colorFecha = '#045478'
        break;
      case 'makos-palmira': 
        objClub.url = require("../img/img-fondo-makospalmira.webp")
        objClub.colorTitulo = 'linear-gradient(225deg, #33E1E6, #014259)'
        objClub.colorFecha = '#014259'
        break;
      case 'comfacauca': 
        objClub.url = require("../img/img-fondo-comfacauca.webp")
        objClub.colorTitulo = 'linear-gradient(225deg, #7B9C98, #2B504F)'
        objClub.colorFecha = '#2B504F'
        break;
      case 'dragones-marinos': 
        objClub.url = require("../img/img-fondo-dragonesmarinos.webp")
        objClub.colorTitulo = 'linear-gradient(225deg, #109DB0, #0B2F45)'
        objClub.colorFecha = '#0B2F45'
        break;
      case 'gold-swimmers': 
        objClub.url = require("../img/img-fondo-goldswimmers.webp")
        objClub.colorTitulo = 'linear-gradient(225deg, #CEB26F, #56472D)'
        objClub.colorFecha = '#56472D'
        break;
      case 'estrellas-del-valle': 
        objClub.url = require("../img/img-fondo-estrellas-del-valle.webp")
        objClub.colorTitulo = 'linear-gradient(225deg, #6F9E8F, #126162)'
        objClub.colorFecha = '#126162'
        break;
      case 'pacific': 
        objClub.url = require("../img/img-fondo-pacific.webp")
        objClub.colorTitulo = 'linear-gradient(225deg, #004070, #040D1C)'
        objClub.colorFecha = '#040D1C'
        break;
      default:
        break;
    }
    return objClub;
  }

  useEffect(() =>{
    const auxResultados = [
      { anio: '2024', resultados: [
        {club: 'piranitas', titulo: 'XIII TORNEO REGIONAL DE NATACIÓN PIRAÑITAS'},
        {club: 'fundacion-arbe', titulo: 'COPA 1ER ANIVERSARIO DE LA FUNDACIÓN ARBE TULUÁ FUNDACION ARBE'},
        {club: 'karina-vivas', titulo: 'I COPA REGIONAL DE NATACIÓN KARINA VIVAS KARINA VIVAS'},
        {club: 'orcas-valle', titulo: 'VI FESTITORNEO REGIONAL ORCAS VALLE ORCAS VALLE'},
        {club: 'tiburones-blancos', titulo: 'IV FESTIVAL REGIONAL DE NATACIÓN TIBURONES BLANCOS'},
        {club: 'comfacauca', titulo: 'I TORNEO REGIONAL DE NATACIÓN COMFACAUCA'},
        {club: 'makos-palmira', titulo: 'VI FESTIVAL DEPARTAMENTAL INTERCLUBES DE NATACION MENORES MAKOS PALMIRA'},
        {club: 'dragones-marinos', titulo: 'II FESTIVAL REGIONAL DE NATACIÓN DRAGONES MARINOS'},
        {club: 'makos-palmira', titulo: 'II FESTIVAL DE NATACIÓN MASTER MAKOS PALMIRA'},
        {club: 'makos-palmira', titulo: 'IV FESTIVAL DE NATACIÓN MAKOS PALMIRA'},
        {club: 'atlas-narino', titulo: 'JUEGOS INTERCOLEGIADOS FASE MUNICIPAL 2024 ATLAS NARIÑO'},
      ]}
    ]

    setResultados(auxResultados);
  },[])

  return(
    <div>
      {resultados.length !== 0 ?(
        resultados.map((result, indexResult)=>{
          return <div key={indexResult}>
            <div className="alert alert-success alert-dismissible fade show pb-1 mb-1" role="alert">
              <h2>{result.anio}</h2>
            </div>
            <motion.div className="d-flex flex-wrap justify-content-center align-items-start position-relative" id="torneos"
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            transition={{duration: 1}}
            >
              {result.resultados.length !== 0 ?(
                result.resultados.map((res, indexRes)=>{
                  return <motion.div
                  className={`position-relative card-torneos card m-1 p-0 shadow no-activo`}
                  style={{ cursor: 'pointer'}}
                  key={indexRes}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 1, delay: `0.${indexRes}5` }}
                  onClick={() => window.open(require(`../resultados${result.anio}/${res.titulo.toUpperCase()}.pdf`), '_blank')}
                >
                  <span className='linea linea-left'></span>
                  <span className='linea linea-right'></span>
                  <span className='linea linea-top'></span>
                  <span className='linea linea-bottom'></span>
                  <div className='position-relative'>
                    <img className='w-100' style={{height: '250px'}} src={traerImagenTorneo(res.club).url} alt="imagen logo club" />
                  </div>
                  <div className="position-relative card-body pt-1 text-center text-light" style={{background: `${traerImagenTorneo(res.club).colorTitulo}`}}>
                    <div className="position-absolute bottom-0" id={`marquee${indexRes}`} style={{fontFamily: 'Fira Sans Extra Condensed', fontSize: '14px'}}>
                      <span id={`marqueeSpan${indexRes}`}></span>
                    </div>
                    <div className='text-light' style={{fontFamily: 'Anton SC', fontSize: '14px', letterSpacing: '1px', textShadow: '0 0 1px black'}}>
                      {res.titulo.toUpperCase()}
                    </div>
                  </div>
                </motion.div>
                })
              ):(
                <></>
              )}
            </motion.div>
          </div>
        })
      ):(
        <></>
      )}
    </div>
  );
}

export default Resultados;