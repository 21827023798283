import './App.css';
import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { motion } from "framer-motion";
import { Toaster } from 'react-hot-toast';
import Loading from './components/Loading';
import WebFont from 'webfontloader';
import Caracteristicas from './components/Caracteristicas'; 
import Pdfs from './components/Pdfs';
import Torneos from './components/Torneos';
import Sesion from './components/Sesion';
import { requestNotificationPermission, sesionIniciada, traerRecords } from './controllers/configuracionFirebase';
import { fetchData } from './controllers/tiempoLimiteInscripcion';
import Records from './components/Records';
import Resultados from './components/Resultados';
import { Chip } from 'primereact/chip';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Avatar } from 'primereact/avatar';
import { chat } from './controllers/ai';
import { Button } from 'primereact/button';

function App() {

  const [ usuario, setUsuario ] = useState();
  const [ isLoading, setIsLoading ] = useState(true);
  const [ seconds, setSeconds ] = useState(3);
  const [ pdf, setPdf ] = useState();
  const [ fechaGoogle, setFechaGoogle ] = useState();
  const [ recordsMundial, setRecordsMundial ] = useState();
  const [ recordsNacional, setRecordsNacional ] = useState();
  const [ messageEspera, setMessageEspera ] = useState(false);
  const [ message, setMessage ] = useState("");
  const [ messages, setMessages ] = useState([
    {
      role: "user",
      parts: [{ text: "" }],
    },
    {
      role: "model",
      parts: [{ text: "¡Hola! Soy Bolt, la mascota de PROWEBSPORTS 😊 Estoy aquí para resolver tus dudas. ¿En qué puedo ayudarte hoy? 🤔✨" }],
    },
  ]);

  const asistenteRef = useRef(null);
  const containerRef = useRef(null);
  const mensajeRef = useRef(null);
  const escribiendoRef = useRef(null);

  const handleSend = () => {
    if (message.trim()) {
      setMessageEspera(true);
      if (escribiendoRef.current) {
        escribiendoRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
      chatbot(message);
      setMessage("");
    }
  };
  
  async function chatbot(text){
    try {
      const result  = await chat.sendMessage(text);
      const auxHistorial = [...messages];
      const chatActual = [{
        role: "user",
        parts: [{ text }],
      },
      {
        role: "model",
        parts: [{ text: result.response.text() }],
      }]
      setMessages([...auxHistorial, ...chatActual]);
      setMessageEspera(false);
    } catch (error) {
      console.error("Error al obtener la respuesta: ", error);
    }
  }

  useEffect(() => {
    sesionIniciada(setUsuario);
    WebFont.load({
      google: {
        families: [
          'Abril Fatface',
          'IBM Plex Mono', 
          'Acme',
          'Yeseva One',
          'Fira Sans Extra Condensed',
          'Poppins',
          'Merriweather Sans',
          'Bebas Neue',
          'Bodoni Moda',
          'Anton SC',
          'Gothic A1',
        ]
      }
    });

    const interval = setInterval(() => {
      setSeconds((seconds) => seconds - 1);
    }, 920);
    if (seconds === 0) {
      setIsLoading(false);
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [seconds])

  useEffect(() =>{
    if (mensajeRef.current && containerRef.current) {
      mensajeRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  },[messages])

  useEffect(() =>{
    const waitForRef = () => {
      if (asistenteRef.current) {
        const auxEvent = { currentTarget: document.getElementById('asistente') };
        asistenteRef.current.show(auxEvent);
      } else {
        setTimeout(waitForRef, 4000);
      }
    };

    waitForRef(); 
  },[])

  useEffect(() => {
    fetchData(setFechaGoogle);
  }, []);

  useEffect(() =>{
    traerRecords(setRecordsMundial, setRecordsNacional);
    requestNotificationPermission();
  },[])

  return (
    <div className="App">
      {isLoading ?(
        <Loading/>
      ):(
      <Router>
        <motion.nav
          id="navbar-example2"
          className="navbar navbar-dark navbar-expand-lg bg-dark"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
          style={{zIndex: 1000}}
        >
          <div className="container-fluid">
            <Link
              className="navbar-brand d-flex align-items-center"
              to="/admin"
            >
              <img
                style={{ width: "45px" }}
                src={require("./img/img_logo_swimmingT.png")}
                alt="logo prowebsports"
              />
            </Link>
            <Chip label={<span><b>BOLT</b> Asistente</span>} id='asistente' image={require('./img/pwsmascota-izquierda.png')} style={{top: '15px', right: '10px', zIndex: '1000', cursor: 'pointer'}} onClick={(e) => asistenteRef.current.toggle(e)}/>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="w-100 navbar-nav justify-content-end me-auto mb-2 mb-lg-0">
                <li className="nav-item item-principal">
                  <Link
                    className="nav-link link-principal"
                    to="/"
                  >TORNEOS</Link>
                </li>
                <li className="nav-item item-principal">
                  <Link
                    className="nav-link link-principal"
                    to="/resultados"
                  >RESULTADOS</Link>
                </li>
                <li className="nav-item item-principal">
                  <Link
                    className="nav-link link-principal"
                    to="/ranking"
                  >RANKING</Link>
                </li>
              </ul>
            </div>
          </div>
        </motion.nav>
        <div className="bgColombia"></div>
        <div className="bg"></div>
        <div className="bg bg2"></div>
        <div className="bg bg3"></div>
        <Routes>
          <Route
            path="/pdf"
            element={
              <Pdfs filePdf={pdf}/>
            }
          />
          <Route
            path="/ranking"
            element={
              <Records 
              recordsMundial={recordsMundial}
              recordsNacional={recordsNacional}/>
            }
          />
          <Route
            path="/resultados"
            element={
              <Resultados />
            }
          />
          <Route
            path="/admin/*"
            element={
              <Sesion usuario={usuario} />
            }
          />
          <Route
            path="/*"
            element={
              <Torneos pdf={pdf} setPdf={setPdf} 
              fechaGoogle={fechaGoogle}/>
            }
          />
        </Routes>
        <OverlayPanel className='w-100 chat' ref={asistenteRef}>
          <div ref={containerRef} style={{ maxHeight: '300px', overflowY: 'auto'}}>
            {messages.map((mess, indexMess)=>{
              return <motion.div key={indexMess} className='d-flex'
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{duration: 1, delay: `0.${indexMess}`}}
              >
                {indexMess % 2 !== 0 ? (
                  <Avatar image={require('./img/pwsmascota-izquierda.png')} shape="circle" size='small'/>
                ):(
                  <></>
                )}
                <p
                ref={indexMess === messages.length - 1 ? mensajeRef : null}
                className={`${mess?.parts[0].text !== '' ? 'p-1' : ''} m-0 ${indexMess % 2 === 0 ? 'ms-auto bg-primary' : 'me-auto bg-danger'} text-start text-light mb-1`} 
                style={{borderRadius: '10px', width: '85%'}}>{mess?.parts[0].text}</p>
              </motion.div>
            })}
            <span
            ref={escribiendoRef}
            className='d-flex justify-content-start mt-2' style={{width: '85%'}}>
              <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: messageEspera ? 1 : 0 }}
              >Escribiendo...</motion.p>
            </span>
          </div>
          <div className="input-group mt-3">
            <textarea
            type="text"
            className="form-control"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Escribe un mensaje"
            rows={1}
            style={{ resize: 'none', overflow: 'hidden' }}
            ></textarea>
            <Button icon='pi pi-send' className="btn btn-success" onClick={handleSend}></Button>
          </div>
        </OverlayPanel>
      </Router>
      )}
      <Toaster containerStyle={{textAlign: 'start'}}/>
    </div>
  );
}

export default App;
