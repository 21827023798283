import React, { useState, useEffect } from 'react';
import { motion } from "framer-motion";
import Conctatenos from './Conctatenos';
import { aplicarVerificacionCorreo, cambiarPassword, cancelarTiemposResultadosFamilia, registrarReaccion, registrarVisualizaciones, traerClientes, traerReacciones, traerResultadosFamilia, traerVisualizaciones, verificarCode } from '../controllers/configuracionFirebase';
import { time, timeLocal } from '../controllers/tiempoLimiteInscripcion';
import { arreglarNombresCortos, arreglarTiempoEstandar } from '../controllers/pdf';
import { FcApproval, FcHighPriority } from "react-icons/fc";
import { Dropdown } from 'primereact/dropdown';
import { Tooltip as Mensaje } from "react-tooltip";
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from "primereact/inputtext";
import { Accordion, AccordionTab } from 'primereact/accordion';
import { getCookieReaccion, setReaccionCookie } from "../controllers/cookies";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Password } from "primereact/password";
import toast from 'react-hot-toast';
import videoFile from '../videos/loading.mp4';
import { IoLogoFacebook, IoLogoWhatsapp, IoMdMail, IoLogoInstagram, IoLogoYoutube, IoIosPaperPlane } from 'react-icons/io'
import Calendario from './Calendario';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Torneos = ({ pdf, setPdf, fechaGoogle }) => {

  const [ cliente, setCliente ] = useState([]);
  const [ clientesClub, setClientesClub ] = useState([]);
  const [ selectedClub, setSelectedClub ] = useState(null);
  const [ dialogHistorial, setDialogHistorial ] = useState(false);
  const [ dialogCotizacion, setDialogCotizacion ] = useState(false);
  const [ dialogChampions, setDialogChampions ] = useState(false);
  const [ dialogResultados, setDialogResultados ] = useState(false);
  const [ dialogCalendario, setDialogCalendario ] = useState(false);
  const [ historialDeportista, setHistorialDeportista ] = useState();
  const [ resultados, setResultados ] = useState([]);
  const [ reacciones, setReacciones ] = useState();
  const [ globalFilter, setGlobalFilter ] = useState('');
  const [ cantPuestos, setCantPuestos ] = useState('');
  const [ identidad, setIdentidad ] = useState('');
  const [ nombreClub, setNombreClub ] = useState('');
  const [ nombresClubs, setNombresClubs ] = useState('');
  const [ animateList, setAnimateList ] = useState(false);
  const [ contadorQueryQr, setContadorQueryQr ] = useState(0);
  const [ contadorQueryNewPassword, setContadorNewPassword ] = useState(0);
  const [ nuevaContrasena, setNuevaContrasena ] = useState('');
  const [ dialogNuevaContrasena, setDialogNuevaContrasena ] = useState(false);

  const resultadosRef = React.useRef(resultados);
  const query = useQuery();
  const navigate = useNavigate();

  function traerImagenTorneo(club){
    const objClub = {
      url: require("../img/img_logo_swimmingT.png"),
      colorTitulo: 'rgb(0,0,0)',
      colorFecha: 'rgb(0,0,0)'
    }
    switch (club) {
      case 'piranitas':
        objClub.url = require("../img/img-fondo-piranitas.webp")
        objClub.colorTitulo = 'linear-gradient(225deg, #CBC2AC, #2E3939)'
        objClub.colorFecha = '#2E3939'
        break;
      case 'polares':
        objClub.url = require("../img/img-fondo-polares.webp")
        objClub.colorTitulo = 'linear-gradient(225deg, #9CBDC3, #0F5D7D)'
        objClub.colorFecha = '#0F5D7D'
        break;
      case 'orcas-valle': 
        objClub.url = require("../img/img-fondo-orcasvalle.webp")
        objClub.colorTitulo = 'linear-gradient(225deg, #4991BF, #183D58)'
        objClub.colorFecha = '#183D58'
        break;
      case 'linavalle': 
        objClub.url = require("../img/img-fondo-linavalle.webp")
        objClub.colorTitulo = 'linear-gradient(225deg, #D0161D, #050D12)'
        objClub.colorFecha = '#050D12'
        break;
      case 'karina-vivas': 
        objClub.url = require("../img/img-fondo-karinavivas.webp")
        objClub.colorTitulo = 'linear-gradient(225deg, #7FADC0, #1A5881)'
        objClub.colorFecha = '#1A5881'
        break;
      case 'fundacion-arbe': 
        objClub.url = require("../img/img-fondo-arbe.webp")
        objClub.colorTitulo = 'linear-gradient(225deg, #C3D6EA, #093A8F)'
        objClub.colorFecha = '#093A8F'
        break;
      case 'fecna': 
        objClub.url = require("../img/img-fondo-fecna.webp")
        break;
      case 'atlas-narino': 
        objClub.url = require("../img/img-fondo-atlas.webp")
        objClub.colorTitulo = 'linear-gradient(225deg, #749DB6, #070F1D)'
        objClub.colorFecha = '#070F1D'
        break;
      case 'leones-acuaticos': 
        objClub.url = require("../img/img-fondo-leonesacuaticos.webp")
        objClub.colorTitulo = 'linear-gradient(225deg, #CBC9C8, #616F7A)'
        objClub.colorFecha = '#616F7A'
        break;
      case 'tiburones-blancos': 
        objClub.url = require("../img/img-fondo-tiburonesblancos.webp")
        objClub.colorTitulo = 'linear-gradient(225deg, #589BA5, #1F3A4B)'
        objClub.colorFecha = '#1F3A4B'
        break;
      case 'kairos': 
        objClub.url = require("../img/img-fondo-kairos.webp")
        objClub.colorTitulo = 'linear-gradient(225deg, #379CB0, #045478)'
        objClub.colorFecha = '#045478'
        break;
      case 'makos-palmira': 
        objClub.url = require("../img/img-fondo-makospalmira.webp")
        objClub.colorTitulo = 'linear-gradient(225deg, #33E1E6, #014259)'
        objClub.colorFecha = '#014259'
        break;
      case 'comfacauca': 
        objClub.url = require("../img/img-fondo-comfacauca.webp")
        objClub.colorTitulo = 'linear-gradient(225deg, #7B9C98, #2B504F)'
        objClub.colorFecha = '#2B504F'
        break;
      case 'dragones-marinos': 
        objClub.url = require("../img/img-fondo-dragonesmarinos.webp")
        objClub.colorTitulo = 'linear-gradient(225deg, #109DB0, #0B2F45)'
        objClub.colorFecha = '#0B2F45'
        break;
      case 'gold-swimmers': 
        objClub.url = require("../img/img-fondo-goldswimmers.webp")
        objClub.colorTitulo = 'linear-gradient(225deg, #CEB26F, #56472D)'
        objClub.colorFecha = '#56472D'
        break;
      case 'estrellas-del-valle': 
        objClub.url = require("../img/img-fondo-estrellas-del-valle.webp")
        objClub.colorTitulo = 'linear-gradient(225deg, #6F9E8F, #126162)'
        objClub.colorFecha = '#126162'
        break;
      case 'pacific': 
        objClub.url = require("../img/img-fondo-pacific.webp")
        objClub.colorTitulo = 'linear-gradient(225deg, #004070, #040D1C)'
        objClub.colorFecha = '#040D1C'
        break;
      default:
        break;
    }
    return objClub;
  }

  function pdfOWeb(vencimiento, resultados, website, pdf, club){
    if(vencimiento){
      resultados = window.open('', '_blank');
      resultados.location.href = require(`../resultados/${pdf.toUpperCase() + ' ' + club}.pdf`);
    }else{
      window.open(website, '_blank');
    }
  }

  function enviarALaPlataforma(club, vencimiento, pdf){
    let resultados;
    switch (club) {
      case 'piranitas':
        pdfOWeb(vencimiento, resultados, 'https://pws-piranitas.web.app', pdf, 'PIRAÑITAS');
        break;
      case 'polares':
        pdfOWeb(vencimiento, resultados, 'https://pws-polares.web.app', pdf, 'POLARES');
        break;
      case 'orcas-valle':
        pdfOWeb(vencimiento, resultados, 'https://www.orcas-valle.com', pdf, 'ORCAS VALLE');
        break;
      case 'linavalle':
        pdfOWeb(vencimiento, resultados, 'https://pws-liga-valle.web.app', pdf, 'LINAVALLE');
        break;
      case 'karina-vivas':
        pdfOWeb(vencimiento, resultados, 'https://pws-karina-vivas.web.app', pdf, 'KARINA VIVAS');
        break;
      case 'fundacion-arbe':
        pdfOWeb(vencimiento, resultados, 'https://pws-arbe.web.app', pdf, 'FUNDACION ARBE');
        break;
      case 'fecna':
        pdfOWeb(vencimiento, resultados, 'https://pws-fecna.web.app', pdf, 'FECNA');
        break;
      case 'atlas-narino':
        pdfOWeb(vencimiento, resultados, 'https://pws-atlas.web.app', pdf, 'ATLAS NARIÑO');
        break;
      case 'leones-acuaticos':
        pdfOWeb(vencimiento, resultados, 'https://pws-leones-acuaticos.web.app', pdf, 'LEONES ACUATICOS');
        break;
      case 'tiburones-blancos':
        pdfOWeb(vencimiento, resultados, 'https://pws-tiburones-blancos.web.app', pdf, 'TIBURONES BLANCOS');
        break;
      case 'makos-palmira':
        pdfOWeb(vencimiento, resultados, 'https://pws-makos.web.app', pdf, 'MAKOS PALMIRA');
        break;
      case 'comfacauca':
        pdfOWeb(vencimiento, resultados, 'https://pws-comfacauca.web.app', pdf, 'COMFACAUCA');
        break;
      case 'dragones-marinos':
        pdfOWeb(vencimiento, resultados, 'https://pws-dragones-marinos.web.app', pdf, 'DRAGONES MARINOS');
        break;
      case 'gold-swimmers':
        pdfOWeb(vencimiento, resultados, 'https://pws-gold-swimmers.web.app', pdf, 'GOLD SWIMMERS');
        break;
      case 'kairos':
        pdfOWeb(vencimiento, resultados, 'https://pws-kairos.web.app', pdf, 'KAIROS');
        break;
      case 'estrellas-del-valle':
        pdfOWeb(vencimiento, resultados, 'https://pws-estrellas-del-valle.web.app', pdf, 'ESTRELLAS DEL VALLE');
        break;
      case 'pacific':
        pdfOWeb(vencimiento, resultados, 'https://pws-pacific.web.app', pdf, 'PACIFIC');
        break;
      default:
        break;
    }
  }

  function manejoRendimiento(record, tiempo){
    if(tiempo && record && record !== '0:0:0' && tiempo !== '0:0:0'){
      const dividirTiempoA = record.split(':');
      const dividirTiempoB = tiempo.split(':');
      const tiempoMinA = dividirTiempoA[0];
      const tiempoSegA = dividirTiempoA[1];
      const tiempoMiliA = dividirTiempoA[2];
      const tiempoMinB = dividirTiempoB[0];
      const tiempoSegB = dividirTiempoB[1];
      const tiempoMiliB = dividirTiempoB[2];
      const tiempoA = parseInt(tiempoMinA) * 60 * 1000 + parseInt(tiempoSegA) * 1000 + parseInt(tiempoMiliA);
      const tiempoB = parseInt(tiempoMinB) * 60 * 1000 + parseInt(tiempoSegB) * 1000 + parseInt(tiempoMiliB);

      const resultadoRecords = parseFloat((tiempoA - tiempoB));
      
      return resultadoRecords;
    }else{
      return 0;
    }
  }

  function arreglarFormatoTiempo(resultadoRecords){
    const auxResultadoRecords = Math.abs(resultadoRecords);
    const minutos = Math.floor(auxResultadoRecords / (60 * 1000));
    const segundos = Math.floor((auxResultadoRecords % (60 * 1000)) / 1000);
    const milisegundos = auxResultadoRecords % 1000;
    if(minutos !== 0){
      return minutos + ':' + segundos + '.' + milisegundos;
    }else{
      return segundos + '.' + milisegundos;
    }
  }

  function sacarHistorialDeportista(nad){
    setDialogHistorial(true);
    const auxHistorial = [];
    resultados.forEach((result)=>{
      const objHistorial = {};
      result.nadadores.forEach((nadad, indexNadad)=>{
        if(nadad.identificacion === nad.identificacion && !nadad.equipo){
          objHistorial.puesto = indexNadad + 1;
          objHistorial.nPrueba = result.id + 1;
          objHistorial.prueba = result.prueba.prueba;
          objHistorial.record = nadad.equipo === null ? nadad.record : '0:0:0';
          objHistorial.tiempo = nadad.tiempo;
          auxHistorial.push(objHistorial);
        }

        if(nadad.equipo){
          const auxNombre = nadad.equipo.filter((eq) => {return eq === nad.nombre});
          if(auxNombre.length !== 0){
            objHistorial.puesto = indexNadad + 1;
            objHistorial.nPrueba = result.id + 1;
            objHistorial.prueba = result.prueba.prueba;
            objHistorial.record = nadad.equipo === null ? nadad.record : '0:0:0';
            objHistorial.tiempo = nadad.tiempo;
            auxHistorial.push(objHistorial);
          }
        }
      })
    })
    nad.pruebas = [...auxHistorial];
    setHistorialDeportista(nad);
  }

  function onClicReaccion(reaccion){
    if(reacciones){
      if(getCookieReaccion('reaxx') !== reaccion){
        if(getCookieReaccion('reaxx') !== ''){
          reacciones[getCookieReaccion('reaxx')] = `${parseInt(reacciones[getCookieReaccion('reaxx')]) - 1}`; 
          reacciones[reaccion] = `${parseInt(reacciones[reaccion]) + 1}`;
        }else{
          reacciones[reaccion] = `${parseInt(reacciones[reaccion]) + 1}`; 
        }
        registrarReaccion(reacciones);
      }
      setReaccionCookie('reaxx', reaccion);
    }
  }

  function onClicTituloTorneo(){
    const idTorneo = document.getElementById('idTorneo').value;
    if(idTorneo !== '' && cliente.length !== 0){
      const auxCliente = cliente.filter((clie) => {return clie.id === idTorneo});
      if(auxCliente.length !== 0){
        traerResultadosFamilia(idTorneo, setResultados, auxCliente[0].club);
        registrarVisualizaciones(auxCliente[0]);
      }
    }
  }

  function formatNumeroReacciones(number) {
    if (number >= 1e6) {
      // Si es un millón o más, formatea en millones (M)
      return (number / 1e6).toFixed(1).replace(/\.0$/, '') + 'M';
    } else if (number >= 1e3) {
      // Si es mil o más, formatea en miles (k)
      return (number / 1e3).toFixed(1).replace(/\.0$/, '') + 'k';
    } else {
      // Si es menos de mil, devuelve el número original
      return number.toString();
    }
  }

  function onSubmitRestablecerContrasena(e){
    e.preventDefault();
    if(query.get('oobCode')){
      verificarCode(query.get('oobCode')).then(()=>{
        toast.success('Codigo de verificación correcto.');
        const auxNuevaContrasena = nuevaContrasena;
        cambiarPassword(query.get('oobCode'), auxNuevaContrasena, query.get('continueUrl'));
        setDialogNuevaContrasena(false);
        navigate('/');
      }).catch((error)=>{
        switch (error.code) {
          case 'auth/expired-action-code':
            toast.error("El enlace de restablecimiento de contraseña ha expirado. Por favor, solicita un nuevo enlace.");
            break;
          case 'auth/invalid-action-code':
            toast.error("El enlace de restablecimiento de contraseña no es válido o ya ha sido utilizado.");
            break;
          case 'auth/user-disabled':
            toast.error("Esta cuenta ha sido deshabilitada. Contacta al soporte para obtener ayuda.");
            break;
          case 'auth/user-not-found':
            toast.error("No se pudo encontrar una cuenta asociada a este enlace. Verifica tu correo electrónico o intenta nuevamente.");
            break;
          case 'auth/weak-password':
            toast.error("La nueva contraseña es demasiado débil. Asegúrate de que tenga al menos 6 caracteres.");
            break;
          default:
            toast.error("Ocurrió un error inesperado. Por favor, intenta nuevamente.");
        }
      })
    }else{
      toast.error('¡UPS! Ocurrio un error. Intentalo de nuevo.');
    }
    e.target.reset();
  }

  useEffect(() =>{
    traerReacciones(setReacciones);
  },[])

  useEffect(() =>{
    traerClientes(setCliente);
  },[])

  useEffect(()=>{
    cliente.forEach((clie, indexClie)=>{
      time(clie, indexClie, fechaGoogle);
      timeLocal(clie.fechaInscripciones, indexClie);
    })
  },[cliente, fechaGoogle])

  useEffect(() =>{
    function nombresClubs(nombre){
      const clubMinus = nombre.toUpperCase();
      let nombreclub = '';
      if (clubMinus.indexOf('-') !== -1) {
        nombreclub = clubMinus.replace(/-/g, " ");
      } else {
        nombreclub = clubMinus;
      }
  
      return nombreclub;
    }

    function acomodarCliente(){
      const auxClientesClub = [];
      cliente.forEach((clie)=>{
        const objCliente = {
          name: nombresClubs(clie.club),
          code: clie.club
        }
        auxClientesClub.push(objCliente);
        traerVisualizaciones(clie);
      })
      setClientesClub(auxClientesClub);
    }

    acomodarCliente();
  },[cliente])

  useEffect(() => {
    // Comparamos los arreglos anterior y actual para detectar cambios de posición
    if (JSON.stringify(resultadosRef.current) !== JSON.stringify(resultados)) {
      // Si hay una diferencia en los arreglos, activamos la animación
      setAnimateList(true);
    }

    // Actualizamos la referencia con el nuevo estado del arreglo
    resultadosRef.current = resultados;

    const animationDuration = 1000; // Duración de la animación en milisegundos (aquí 0.5 segundos)
    const timer = setTimeout(() => {
      setAnimateList(false);
    }, animationDuration);
 
    return () => clearTimeout(timer);
  }, [setAnimateList, resultadosRef, resultados])

  useEffect(() =>{
    if(!dialogResultados){
      cancelarTiemposResultadosFamilia();
      setResultados([]);
    }
  },[dialogResultados])

  useEffect(() =>{
    function sacarNombresClubes(){
      const auxNombres = new Map();
      resultados.forEach((res)=>{
        res.nadadores.forEach((nad)=>{
          const objNombres = {
            name: nad.nombreclub,
            code: nad.club
          }
          auxNombres.set(nad.nombreclub, objNombres);
        })
      })

      setNombresClubs(Array.from(auxNombres.values()));
    }

    sacarNombresClubes();
  },[resultados])

  useEffect(() =>{
    if(query.get('oobCode') && query.get('mode') === 'resetPassword'){
      setDialogNuevaContrasena(true);
      setContadorNewPassword(1);
    }

    if(query.get('oobCode') && query.get('mode') === 'verifyEmail' && contadorQueryQr === 0){
      aplicarVerificacionCorreo(query.get('oobCode'));
      if(query.get('continueUrl')){
        let contador = 10;
        const interval = setInterval(() => {
          if (contador === 0) {
            window.location.href = `${query.get('continueUrl')}`;
            clearInterval(interval);
            contador = 10;
          }
          contador--;
        }, 1000);
      }
      setContadorQueryQr(1);
      navigate('/');
    }
  },[query])

  return (
    <>
      <motion.div className="text-center position-relative"
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{duration: 1}}
      >
        <Accordion activeIndex={0}>
          <AccordionTab header="¿Qué opinas de nuestra plataforma?">
            <div className="d-flex justify-content-around mt-2 m-auto" style={{width: '200px'}}>
              <div className="position-relative">
                <img className='img-reaccion' src={require('../img/gif-meencanta.gif')} alt="emoji" onClick={() => onClicReaccion('meencanta')} style={{cursor:'pointer', width: '50px'}}/>
                <span className={`position-absolute start-100 translate-middle badge rounded-pill ${getCookieReaccion('reaxx') === 'meencanta' ? 'bg-danger' : 'bg-success'}`} style={{top: '6px'}}>
                  {reacciones ? formatNumeroReacciones(parseInt(reacciones.meencanta)) : '0'}
                </span>
              </div>
              <div className="position-relative">
                <img className='img-reaccion' src={require('../img/gif-like.gif')} alt="emoji" onClick={() => onClicReaccion('like')} style={{cursor:'pointer', width: '50px'}}/>
                <span className={`position-absolute start-100 translate-middle badge rounded-pill ${getCookieReaccion('reaxx') === 'like' ? 'bg-danger' : 'bg-success'}`} style={{top: '6px'}}>
                  {reacciones ? formatNumeroReacciones( parseInt(reacciones.like)) : '0'}
                </span>
              </div>
              <div className="position-relative" style={{top: '12px'}}>
                <img className='img-reaccion' src={require('../img/gif-dislike.gif')} alt="emoji" onClick={() => onClicReaccion('dislike')} style={{cursor:'pointer', width: '50px'}}/>
                <span className={`position-absolute start-100 translate-middle badge rounded-pill ${getCookieReaccion('reaxx') === 'dislike' ? 'bg-danger' : 'bg-success'}`} style={{top: '-4px'}}>
                  {reacciones ? formatNumeroReacciones(parseInt(reacciones.dislike)) : '0'}
                </span>
              </div>
            </div>
          </AccordionTab>
        </Accordion>
      </motion.div>
      <motion.div id="inicio" style={{zIndex: 100}}
      initial={{opacity: 0}}
      animate={{opacity: 1}}
      transition={{duration: 1}}
      >
        <div className='d-flex flex-wrap align-items-center text-center container-inicio mt-1' style={{height: '100%'}}>
          <motion.div
          initial={{x: -200}}
          whileInView={{x: 0}}
          transition={{duration: 1}}
          className='w-100 position-relative'
          style={{maxWidth: '450px'}}
          >
            <video autoPlay loop muted style={{borderRadius: '0 50px 50px 0'}} className='w-100 shadow img-inicio'>
              <source src={videoFile} type="video/mp4" />
              Tu navegador no admite la reproducción de videos.
            </video>
            <div className='d-flex align-items-center redes-sociales mt-1 ms-1'>
              <Link to="mailto:pws@prowebsports.com.co" target='_blank' style={{textDecoration: 'none'}}><IoMdMail className='text-secondary'/></Link>
              <Link to="https://wa.me/573245884041" target='_blank' style={{textDecoration: 'none'}}><IoLogoWhatsapp className='text-success'/></Link>
              <Link to="https://t.me/573245884041" target='_blank' style={{textDecoration: 'none'}}><IoIosPaperPlane className='text-info'/></Link>
              <Link to="https://www.facebook.com/emanuel.barbosa.908132" target='_blank' style={{textDecoration: 'none'}}><IoLogoFacebook className='text-primary'/></Link>
              <Link to="https://www.instagram.com/prowebsports/" target='_blank' style={{textDecoration: 'none'}}><IoLogoInstagram style={{color: 'purple'}}/></Link>
              <Link to="https://www.youtube.com/@emanuelbarbosa2735" target='_blank' style={{textDecoration: 'none'}}><IoLogoYoutube className='text-danger'/></Link>
            </div>
          </motion.div>
          <motion.div className='inicio m-auto'
          initial={{opacity: 0}}
          whileInView={{opacity: 1}}
          transition={{duration: 1.2, delay: 0.3}}
          >
            <div>
              <h4 style={{fontFamily: 'Anton SC', lineHeight: '1'}}>SOLUCIONES TECNOLÓGICAS PARA COMPETENCIAS DE NATACIÓN</h4>
            </div>
            <div style={{fontFamily: 'Gothic A1', textAlign: 'justify', lineHeight: '1.1'}}>
              <p>
              <b>¡Bienvenidos al futuro de la gestión de competencias de natación!</b> Con nuestras plataformas innovadoras y <b>CRONOSPORTS</b>, 
              el sistema de cronometraje digital más avanzado, llevamos tus eventos a otro nivel. Resultados en tiempo real, 
              precisión impecable y cero errores manuales: todo diseñado para clubes, escuelas, fundaciones y cajas de compensación. <br />
              <b>¡Simplifica, innova y deslumbra con competencias más profesionales que nunca!</b>
              </p>
            </div>
          </motion.div>
        </div>
      </motion.div>
      <motion.div className="d-flex flex-wrap justify-content-center align-items-start position-relative" id="torneos" style={{zIndex: 100}}
      initial={{opacity: 0}}
      animate={{opacity: 1}}
      transition={{duration: 1}}
      >
        <div className="wave-button-container" style={{bottom: '140px', right: '0', zIndex: 10000, width: '90px'}}>
          <Button icon='pi pi-bolt' className='shadow wave-buttonResultados' size="large" severity='danger' tooltip='Resultados en tiempo real' tooltipOptions={{position: 'left', mouseTrack: true, mouseTrackTop: 15}} style={{borderRadius: '50%'}}
          onClick={() =>setDialogResultados(true)}></Button>
        </div>
        <div className="wave-button-container" style={{bottom: '75px', right: '0', zIndex: 10000, width: '90px'}}>
          <Button icon='pi pi-calendar' className='shadow wave-buttonCalendar' size="large" severity='danger' tooltip='Calendario' tooltipOptions={{position: 'left', mouseTrack: true, mouseTrackTop: 15}} style={{borderRadius: '50%'}}
          onClick={() =>setDialogCalendario(true)}></Button>
        </div>
        <div className="wave-button-container" style={{bottom: '10px', right: '0', zIndex: 10000, width: '90px'}}>
          <Button icon='pi pi-dollar' className='shadow wave-button' size="large" severity='success' tooltip='Cotizar' tooltipOptions={{position: 'left', mouseTrack: true, mouseTrackTop: 15}} style={{borderRadius: '50%'}}
          onClick={() =>setDialogCotizacion(true)}></Button>
        </div>
        <motion.div
          className={`position-relative card-publicidad card m-1 p-0 shadow activo`}
          style={{ cursor: 'pointer'}}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: `0.5` }}
        >
          <span className='linea linea-left'></span>
          <span className='linea linea-right'></span>
          <span className='linea linea-top'></span>
          <span className='linea linea-bottom'></span>
          <div className='position-relative'>
            <video controls loop className='w-100' height='300px'>
              <source src={require('../videos/cronosports.mp4')} type="video/mp4" />
              Tu navegador no admite la reproducción de videos.
            </video>
            <div className="w-100 position-absolute top-0 text-dark bg-light shadow">
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <motion.p
                    className='mt-1 ms-2 m-0'
                    style={{ fontSize: "15px", fontFamily: "Anton SC", letterSpacing: '1px' }}
                  >
                    PROWEBSPORTS - CRONOSPORTS
                  </motion.p>
                </div>
                <div className='text-end mt-1 me-2' style={{fontFamily: 'Fira Sans Extra Condensed', fontSize: '15px', fontWeight: 'bold'}}> 
                  <span>Publicidad</span>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
        {cliente.length !== 0 ?(
          cliente.filter((clie)=>clie.club.includes(selectedClub?.code ? selectedClub.code : '')).map((torn, indexTorn)=>{
            return <motion.div
            className={`position-relative card-torneos card m-1 p-0 shadow ${torn.vencimiento ? 'no-activo' : 'activo'}`}
            style={{ cursor: 'pointer', width: '19rem'}}
            onClick={() => enviarALaPlataforma(torn.club, torn.vencimiento, torn.titulo)}
            key={indexTorn}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: `0.${indexTorn}5` }}
          >
            <span className='linea linea-left'></span>
            <span className='linea linea-right'></span>
            <span className='linea linea-top'></span>
            <span className='linea linea-bottom'></span>
            <div className='position-relative'>
              <img className='w-100' src={traerImagenTorneo(torn.club).url} alt="imagen logo club" />
              <div className="w-100 position-absolute top-0 text-dark bg-light shadow">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <img
                      className="mt-1 ms-2"
                      style={{ width: "17px", marginBottom: '1px' }}
                      src={require("../img/icon-usuario-curso.webp")}
                      alt="icono del usuario"
                      id={`cantNadadores${indexTorn}`}
                    />
                    <p
                      id={`cantNadadores${indexTorn}`}
                      className='mt-1 m-0'
                      style={{ fontSize: "15px", fontFamily: "Anton SC", letterSpacing: '1px' }}
                    >
                      {parseInt(torn.deportistas) < 10 && parseInt(torn.deportistas) !== 0 ? '0' + torn.deportistas : torn.deportistas}
                    </p>
                    <p
                      id={`cantVisualizaciones${indexTorn}`}
                      className='ms-1 mt-1 m-0'
                      style={{ fontSize: "15px", fontFamily: "Anton SC", letterSpacing: '1px' }}
                    > 
                      <i className='pi pi-eye' style={{marginRight: '2px'}}></i>{parseInt(torn.visualizaciones) < 10 && parseInt(torn.visualizaciones) !== 0 ? '0' + torn.visualizaciones : torn.visualizaciones}
                    </p>
                  </div>
                  <div className='text-end mt-1 me-2' id={`contenedor-diaLocal${indexTorn}`} style={{fontFamily: 'Fira Sans Extra Condensed', fontSize: '15px', fontWeight: 'bold'}}> 
                    <span>Cierre en </span>
                    <span id={`diaLocal${indexTorn}`}>00</span><span>D : </span>
                    <span id={`horaLocal${indexTorn}`}>00</span><span>H : </span>
                    <span id={`minutoLocal${indexTorn}`}>00</span><span>M : </span>
                    <span id={`segundoLocal${indexTorn}`}>00</span><span>S ⏳</span> 
                  </div>
                </div>
              </div>
              <div className='d-flex position-absolute bg-light ps-1 pe-1 shadow' style={{fontFamily: 'Fira Sans Extra Condensed', fontSize: '13px', borderRadius: '0 10px 0 0', bottom: '32px'}}> 
                <p className='m-0' id={`inscripcionesCerradas${indexTorn}`}></p>
                <div id={`contenedor-fecha${indexTorn}`}>
                  <span>Cierre en </span>
                  <span id={`dia${indexTorn}`}>00</span>
                  <span id={`dia-hora${indexTorn}`}></span><span>📅</span> 
                </div>
              </div>
              <Mensaje
                anchorId={`cantNadadores${indexTorn}`}
                place="bottom"
                content={`Inscritos`}
                className="bg-primary shadow"
                style={{ zIndex: 1}}
              />
              <Mensaje
                anchorId={`cantVisualizaciones${indexTorn}`}
                place="bottom"
                content={`Espectadores`}
                className="bg-danger shadow"
                style={{ zIndex: 1}}
              />
            </div> 
            <div className="position-absolute bottom-0 card-footer ps-2 p-1 text-start text-light" style={{background: `${traerImagenTorneo(torn.club).colorFecha}`, width: '83%', borderRadius: '0 10px 0 0'}}>
              <div id={`marquee${indexTorn}`} style={{fontFamily: 'Fira Sans Extra Condensed', fontSize: '16px'}}>
                <span id={`marqueeSpan${indexTorn}`}></span>
              </div>
            </div>
          </motion.div>
          })
        ):(
          <> </>
        )}
      </motion.div>
      <div className='m-auto' style={{maxWidth: '1400px'}}>
        <motion.div className='mt-5 ms-4 d-flex justify-content-start align-items-center'
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{duration: 1}}
        style={{textAlign: 'justify'}}
        >
          <div className='w-50' style={{maxWidth: '150px'}}>
            <img className='w-100' src={require('../img/vision.png')} alt="logo vision" style={{filter: "drop-shadow(-1px 1px 2px black)"}}/>
          </div>
          <div className='w-100 ms-1' style={{maxWidth: '400px'}}>
            <h3 className='mb-0' style={{fontFamily: 'Anton SC'}}>VISIÓN:</h3>
            <p style={{fontFamily: 'Fira Sans Extra Condensed', lineHeight: '15px'}}>
            Nuestra Visión en <b>PROWEBSPORTS</b> es liderar globalmente en gestión de torneos de natación, brindando una solución tecnológica innovadora que ofrece una experiencia de usuario única y soporte técnico de excelencia. Queremos transformar el deporte, eliminando el papeleo y creando un entorno digital eficiente, confiable y sostenible para entrenadores, nadadores y aficionados.
            </p>
          </div>
        </motion.div>
        <motion.div className='mt-1 me-4 d-flex justify-content-end align-items-center'
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{duration: 1}}
        style={{textAlign: 'justify'}}
        >
          <div className='w-100 me-1' style={{maxWidth: '400px'}}>
            <h3 className='mb-0' style={{fontFamily: 'Anton SC'}}>MISIÓN:</h3>
            <p style={{fontFamily: 'Fira Sans Extra Condensed', lineHeight: '15px'}}>
            Nuestra Misión en <b>PROWEBSPORTS</b> es revolucionar la natación mediante soluciones tecnológicas avanzadas que simplifiquen la organización de torneos. Nos dedicamos a ofrecer herramientas innovadoras para entrenadores, mejorando la experiencia deportiva y optimizando cada etapa del proceso para todos los involucrados.
            </p>
          </div>
          <div className='w-50' style={{maxWidth: '150px'}}>
            <img className='w-100' src={require('../img/mision.png')} alt="logo mision" style={{filter: "drop-shadow(-1px 1px 2px black)"}}/>
          </div>
        </motion.div>
      </div>
      <Dialog visible={dialogResultados} style={{ width: '100%' }} header="Resultados en tiempo real" modal className="p-fluid" onHide={() => setDialogResultados(false)}>
      <div className="alert alert-danger alert-dismissible fade show" role="alert">
          <h4>¡IMPORTANTE!</h4>
          <p className="mb-0">¡Hola a todos! Les recordamos que no es necesario recargar la plataforma, ya que los resultados se actualizan en tiempo real. ¡Gracias por su colaboración y paciencia!</p>
          <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
        <div className="form-floating mb-2">
          <select name="idTorneo" id="idTorneo" className='form-select' onChange={() => onClicTituloTorneo()}>
            <option value="">Elige el evento</option>
            {cliente.length !== 0 ? (
              cliente.map((torn,indexTorn)=>{
                return <option key={indexTorn} value={torn.id}>{`${torn.estado === 'evento' ? torn.titulo + ' ' + arreglarNombresCortos(torn.club) + ' - En curso...' : torn.estado === 'inscripciones' ? torn.titulo + ' ' + arreglarNombresCortos(torn.club) + ' - No ha dado inicio aún.' : torn.titulo + ' ' + arreglarNombresCortos(torn.club) + ' - ' + torn.estado}`}</option>
              }))
              :(
              <option value="">Sin eventos aún</option>
              )
            }
          </select>
          <label htmlFor="idTorneo">Eventos</label>
        </div>
        {resultados.length !== 0 ?(
          <div className="d-flex flex-wrap justify-content-center align-items-center mb-1">
            <span className="p-input-icon-left" style={{width: '220px'}}>
              <i className="pi pi-search" />
              <InputText
                type="number"
                onChange={(e) => setGlobalFilter(e.target.value)}
                placeholder="Numero de prueba"
              />
            </span>
            <span className="p-input-icon-left" style={{width: '220px'}}>
              <i className="pi pi-list" />
              <InputText
                type="number"
                onChange={(e) => setCantPuestos(e.target.value)}
                placeholder="Cantidad Puestos"
              />
            </span>
            <span className="p-input-icon-left" style={{width: '220px'}}>
              <i className="pi pi-user" />
              <InputText
                type="number"
                onChange={(e) => setIdentidad(e.target.value)}
                placeholder="Ti/Cc Deportista"
              />
            </span>
            <span className="p-input-icon-left" style={{width: '220px'}}>
              <i className="pi pi-users" />
              <Dropdown value={nombreClub} onChange={(e) => setNombreClub(e.value)} options={nombresClubs} optionLabel="name" 
                placeholder="Elige el club" />
            </span>
          </div>
        ):(
          <></>
        )}
        <div className="d-flex flex-wrap justify-content-center text-center">
          {resultados.length !== 0 ?(
            resultados.filter((resu)=>((resu.id + 1) + '').includes(globalFilter)).map((result, indexResult)=>{
              return <form key={indexResult} className='formulario-tablas table-responsive-sm'>
                <motion.table style={{margin: '4px', width: '530px', borderRadius: '20px 0', background: 'rgb(50, 51, 51)'}}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{duration: 1}}
                className='shadow tabla-tiempos'
                >
                  <thead className='text-light'>
                    <tr style={{borderBottom: '1px solid gray'}}>
                      <td style={{fontSize: '16px', fontFamily: 'Fira Sans Extra Condensed'}} colSpan={7}>
                        {resultados.length - 1 === indexResult ?(
                          <span>
                            {result.nadadores.length === 1 ? 'No Acumula Para El Trofeo - ' : ''} En Curso...
                          </span>
                        ):(
                          <span>
                            {result.nadadores.length === 1 ? 'No Acumula Para El Trofeo - ' : ''} Prueba Terminada ✔
                          </span>
                        )}
                      </td>
                    </tr>
                    <tr style={{borderBottom: '1px solid gray'}}>
                      <th className='id fs-4'>{result.id + 1}</th>
                      <th style={{fontSize: '18px', borderLeft: '1px solid gray'}} colSpan={6}>{result.categoria}</th>
                    </tr>
                    <tr style={{borderBottom: '1px solid gray'}}>
                      <th style={{fontSize: '18px'}} colSpan={7}>{result.prueba.prueba}</th>
                    </tr>
                    <tr>
                      <th style={{fontSize: '15px', borderRight: '1px solid gray'}}>Pto</th>
                      <th style={{fontSize: '15px'}}></th>
                      <th style={{fontSize: '15px', borderRight: '1px solid gray'}}>Deportista</th>
                      <th style={{fontSize: '15px', borderRight: '1px solid gray'}}>T. Insc.</th>
                      <th style={{fontSize: '15px', borderRight: '1px solid gray'}}>Tiempo</th>
                      <th style={{fontSize: '15px', borderRight: '1px solid gray'}}>Rendim.</th>
                      <th style={{fontSize: '15px'}}>Equipo</th>
                    </tr>
                  </thead>
                  <tbody>
                    {result.nadadores.slice(0, cantPuestos === '' ? result.nadadores.length : cantPuestos).map((nadador, indexNadador)=>{
                      return <motion.tr key={indexNadador}
                      initial={{ x: -1000, opacity: 0}}
                      animate={{ x: animateList ? -1000 : 0.1, opacity: animateList ? 0 : 1}}
                      transition={{ duration: 0.5, delay: `0.${indexNadador}`, type: 'spring'}}
                      style={{borderRadius: '20px 0', boxShadow: '0 3px 8px black', zIndex: `1.${indexNadador}`}}
                      >
                        {indexNadador === 0 ? <td className={`${nadador.identificacion === identidad || nadador.nombreclub === nombreClub.name ? 'bg-info text-light' : ''}`} style={{fontFamily: 'Poppins', background: 'white', borderRadius: '20px 0 0 0'}}><img style={{width: '35px', filter: "drop-shadow(-1px 1px 2px black)"}} src={require("../img/icon-medalla-1.png")} alt="medalla primer lugar" /></td> : indexNadador === 1 ? <td className={`${nadador.identificacion === identidad || nadador.nombreclub === nombreClub.name ? 'bg-info text-light' : ''}`} style={{fontFamily: 'Poppins', background: 'white', borderRadius: '20px 0 0 0'}}><img style={{width: '35px', filter: "drop-shadow(-1px 1px 2px black)"}} src={require("../img/icon-medalla-2.png")} alt="medalla segundo lugar" /></td> :  indexNadador === 2 ? <td className={`${nadador.identificacion === identidad || nadador.nombreclub === nombreClub.name ? 'bg-info text-light' : ''}`} style={{fontFamily: 'Poppins', background: 'white', borderRadius: '20px 0 0 0'}}><img style={{width: '35px', filter: "drop-shadow(-1px 1px 2px black)"}} src={require("../img/icon-medalla-3.png")} alt="medalla tercer lugar" /></td> : <td className={`pt-2 pb-2 ${nadador.identificacion === identidad || nadador.nombreclub === nombreClub.name ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white', borderRadius: '20px 0 0 0'}}>{indexNadador + 1}</td>}
                        {nadador.equipo === null ?(
                          <td className={`${nadador.identificacion === identidad || nadador.nombreclub === nombreClub.name ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>
                            <Button type='button' severity='success' icon='pi pi-user-plus' className='btn' style={{borderRadius: '50%', width: '2rem', height: '2rem', padding: 0}} onClick={() => sacarHistorialDeportista(nadador)}/>
                          </td>
                        ):(
                          <td style={{background: 'white'}}></td>
                        )}
                        {nadador.equipo !== null ?(
                          <td className={`text-start ${nadador.identificacion === identidad || nadador.nombreclub === nombreClub.name ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>
                            {nadador.equipo.map((eq, indexEq)=>{
                              return <span key={indexEq}>{eq} <hr className='m-0'/></span>
                            })}
                          </td>
                          ):(
                            <td className={`${nadador.identificacion === identidad || nadador.nombreclub === nombreClub.name ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>{nadador.nombre}</td>
                        )}
                        <td className={`${nadador.identificacion === identidad || nadador.nombreclub === nombreClub.name ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>{nadador.record && nadador.record !== '0:0:0' ? arreglarTiempoEstandar(nadador.record) : nadador.tiempo === '0:0:0' ? 'NSP' : nadador.tiempo === '61:0:0' ? 'DSC' : `${arreglarTiempoEstandar(nadador.tiempo)}`}</td>
                        <td className={`${nadador.identificacion === identidad || nadador.nombreclub === nombreClub.name ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>{nadador.tiempo === '0:0:0' ? 'NSP' : nadador.tiempo === '61:0:0' ? 'DSC' : `${arreglarTiempoEstandar(nadador.tiempo)}`}</td>
                        <td className={`${nadador.identificacion === identidad || nadador.nombreclub === nombreClub.name ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>{manejoRendimiento(nadador.record, nadador.tiempo) >= 0 && nadador.tiempo !== '61:0:0' && nadador.tiempo !== '0:0:0'? 
                          <label className='d-flex align-items-center justify-content-center'><FcApproval />{arreglarFormatoTiempo(manejoRendimiento(nadador.record, nadador.tiempo))}</label>
                          : 
                          <label className='d-flex align-items-center justify-content-center'><FcHighPriority />{arreglarFormatoTiempo(manejoRendimiento(nadador.record, nadador.tiempo === '61:0:0' ? '0:0:0' : nadador.tiempo))}</label>
                        }</td>
                        <td className={`${nadador.identificacion === identidad || nadador.nombreclub === nombreClub.name ? 'bg-info text-light' : ''}`} style={{fontSize: '10px', fontFamily: 'Poppins', background: 'white', borderRadius: '0 0 20px 0'}}>{nadador.nombreclub}</td>
                      </motion.tr>
                    })}
                  </tbody>
                </motion.table>
              </form> 
            })
          ):(
            <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{duration: 1}}
            >
              <div className='p-5 text-center'>
                <img className='m-auto' style={{width: '230px'}} src={require('../img/img_logo_swimmingT.png')} alt="logo prowebsports" />
                <h1>¡Bienvenido!</h1>
                <p>No tenemos registros de tiempos aún.</p>
              </div>
            </motion.div>
          )}
        </div>
        <motion.div className="text-center"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{duration: 1}}
        >
          <h5>¿Qué opinas de nuestra plataforma?</h5>
          <div className="d-flex justify-content-around mt-2 m-auto" style={{width: '200px'}}>
            <div className="position-relative">
              <img className='img-reaccion' src={require('../img/gif-meencanta.gif')} alt="emoji" onClick={() => onClicReaccion('meencanta')} style={{cursor:'pointer', width: '50px'}}/>
              <span className={`position-absolute start-100 translate-middle badge rounded-pill ${getCookieReaccion('reaxx') === 'meencanta' ? 'bg-danger' : 'bg-success'}`} style={{top: '6px'}}>
                {reacciones ? formatNumeroReacciones(parseInt(reacciones.meencanta)) : '0'}
              </span>
            </div>
            <div className="position-relative">
              <img className='img-reaccion' src={require('../img/gif-like.gif')} alt="emoji" onClick={() => onClicReaccion('like')} style={{cursor:'pointer', width: '50px'}}/>
              <span className={`position-absolute start-100 translate-middle badge rounded-pill ${getCookieReaccion('reaxx') === 'like' ? 'bg-danger' : 'bg-success'}`} style={{top: '6px'}}>
                {reacciones ? formatNumeroReacciones( parseInt(reacciones.like)) : '0'}
              </span>
            </div>
            <div className="position-relative" style={{top: '12px'}}>
              <img className='img-reaccion' src={require('../img/gif-dislike.gif')} alt="emoji" onClick={() => onClicReaccion('dislike')} style={{cursor:'pointer', width: '50px'}}/>
              <span className={`position-absolute start-100 translate-middle badge rounded-pill ${getCookieReaccion('reaxx') === 'dislike' ? 'bg-danger' : 'bg-success'}`} style={{top: '-4px'}}>
                {reacciones ? formatNumeroReacciones(parseInt(reacciones.dislike)) : '0'}
              </span>
            </div>
          </div>
        </motion.div>
      </Dialog>
      <Dialog visible={dialogHistorial} style={{ width: '50em' }} breakpoints={{ '700px': '100%' }} header="Historial Deportivo" modal className="p-fluid" onHide={() => setDialogHistorial(false)}>
        <div className="field">
          {historialDeportista ?(
            <div>
              <h1 className='text-center mb-0' style={{fontFamily: 'Poppins', background: 'rgb(212,212,212)'}}>
                {historialDeportista?.equipo === null ? (
                  historialDeportista?.nombre
                ):(
                  historialDeportista?.equipo.map((eq, indexEq)=>{
                    return (historialDeportista?.equipo.length - 1) !== indexEq ? eq + ' - ' : eq;
                  })
                )}
              </h1>
              <h5 className='text-center bg-light' style={{fontFamily: 'Poppins'}}>
                {historialDeportista?.nombreclub}
              </h5>
              <div className='mt-4 mb-4 p-3 d-flex flex-wrap justify-content-around align-items-center' style={{fontFamily: 'Poppins', background: 'rgb(212,212,212)'}}>
                <p className='m-0'><b>Edad:</b> {historialDeportista?.edad}</p>
              </div>
              {historialDeportista?.pruebas.map((prue, indexPrue)=>{
                return <table key={indexPrue} className='w-100 table table-bordered table-striped text-center'>
                <thead>
                  <tr style={{fontFamily: 'Poppins', background: 'rgb(212,212,212)'}}>
                    <th className='fs-6'>{prue.nPrueba}</th>
                    <th className='fs-6' colSpan={4}>{prue.prueba}</th>
                  </tr>
                  <tr>
                    <th>Posición</th>
                    <th>T. Insc.</th>
                    <th>Tiempo</th>
                    <th>Rendim.</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                  {prue.puesto === 1 ? <td style={{fontFamily: 'Poppins'}}><img style={{width: '25px'}} src={require("../img/icon-medalla-1.png")} alt="medalla primer lugar" /></td> : prue.puesto === 2 ? <td style={{fontFamily: 'Poppins'}}><img style={{width: '25px'}} src={require("../img/icon-medalla-2.png")} alt="medalla segundo lugar" /></td> :  prue.puesto === 3 ? <td style={{fontFamily: 'Poppins'}}><img style={{width: '25px'}} src={require("../img/icon-medalla-3.png")} alt="medalla tercer lugar" /></td> : <td className={`pt-2 pb-2`} style={{fontFamily: 'Poppins'}}>{prue.puesto}</td>}
                    <td>{arreglarTiempoEstandar(prue.record)}</td>
                    <td>{prue.tiempo !== '61:0:0' ? prue.tiempo !== '0:0:0' ? arreglarTiempoEstandar(prue.tiempo) : 'NSP' : 'DSC' }</td>
                    <td>{manejoRendimiento(prue.record, prue.tiempo) >= 0 && prue.tiempo !== '61:0:0' && prue.tiempo !== '0:0:0'? 
                      <label className='d-flex align-items-center justify-content-center'><FcApproval />{arreglarFormatoTiempo(manejoRendimiento(prue.record, prue.tiempo))}</label>
                      : 
                      <label className='d-flex align-items-center justify-content-center'><FcHighPriority />{arreglarFormatoTiempo(manejoRendimiento(prue.record, prue.tiempo === '61:0:0' ? '0:0:0' : prue.tiempo))}</label>
                    }</td>
                  </tr>
                </tbody>
              </table>
              })}
            </div>
          ):(
            <></>
          )}
        </div>
      </Dialog>
      <Dialog visible={dialogCotizacion} maximizable style={{ width: '50em' }} breakpoints={{ '641px': '100%' }} header='Cotización' modal className="p-fluid" onHide={() => setDialogCotizacion(false)}>
        <div className="field">
          <div className='mb-2 pdf-min pdf-max' style={{width: '100%'}}>
            <embed src={require('../archivos/cotizacion.pdf')} width='100%' height='100%' type='application/pdf'/>
          </div>
        </div>
      </Dialog>
      <Conctatenos/>
      <Dialog visible={dialogChampions} style={{ width: '50em', height: '2397px' }} breakpoints={{ '700px': '100%' }} header="Encuesta PROWEBSPORTS CHAMPIONS" modal className="p-fluid" onHide={() => setDialogChampions(false)}>
        <div className="field">
          <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSe5SsAEAHqX1_MeGT1cPRyXGzNJGO7fDIW4e9LfWeCzjQgw-Q/viewform?embedded=true" width="100%" height="2550" frameborder="0" marginheight="0" marginwidth="0">Cargando…</iframe>
        </div>
      </Dialog>
      <Dialog visible={dialogNuevaContrasena} style={{ width: '30em' }} breakpoints={{ '700px': '100%' }} header="Restablecer Contraseña" modal className="p-fluid" onHide={() => setDialogNuevaContrasena(false)}>
        <div className="field">
          <form onSubmit={onSubmitRestablecerContrasena}>
            <div className="d-flex justify-content-center">
              <img
                className="w-50 m-auto"
                src={require("../img/img-logo-swimmingt.webp")} 
                alt="logo de prowebsports"
              />
            </div>
            <div className="p-inputgroup flex-1 mb-1">
              <span className="p-inputgroup-addon">
                  <i className={nuevaContrasena.length >= 6 ? 'pi pi-unlock' : 'pi pi-lock'}></i>
              </span>
              <Password value={nuevaContrasena} onChange={(e) => setNuevaContrasena(e.target.value)} placeholder="Contraseña" toggleMask minLength={6} required
              className={`${nuevaContrasena !== '' && nuevaContrasena.length >= 6 ? '' : 'p-invalid'}`}
              feedback={false}/>
            </div>
            <input type="submit" className="btn btn-success w-100" value='Restablecer' />
          </form>
        </div>
      </Dialog>
      <Dialog visible={dialogCalendario} maximizable style={{ width: '50em' }} breakpoints={{ '641px': '100%' }} header='Calendario' modal className="p-fluid" onHide={() => setDialogCalendario(false)}>
        <div className="field">
          <Calendario cliente={cliente}/>
        </div>
      </Dialog>
    </>
  );
}

export default Torneos;